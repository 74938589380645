import { Refresh, Terminal } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Button } from '@mui/material';
import { isAxiosError } from 'axios';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Show,
  TabbedShowLayout,
  TopToolbar,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate,
} from 'react-admin';

import { EditTabButton } from 'components/TabButtons/ToTabButtons';
import { usePermission } from 'providers/auth/permissionProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import RSHDialog from './components/RSHDialog';
import { AppsTab, ConfigTab, DevTab, DiagnosticsTab, InfoTab } from './components/UnitShowTabs';
import { ActivityLogTab } from './components/UnitShowTabs/ActivityLogTab';
import { MobileNetwork } from './components/UnitShowTabs/MobileNetwork';

const UnitActions = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const record = useRecordContext();
  const refresh = useRefresh();
  const translate = useTranslate();
  const { hasPermission } = usePermission();

  const id = get(record, 'id');
  const hardware_id = get(record, 'hardware_id');
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState<string>('');
  const [open, setOpen] = useState(false);
  const handleClose = (_event: object, reason?: string) => {
    if (reason === 'backdropClick') return;
    setOpen(false);
  };

  const handleAction = async (action: string, errorMessage: string, successMessage: string) => {
    setLoading(true);
    setAction(action);
    try {
      await dataProvider.post(`units/${id}/${action}`);
      refresh();
    } catch (error) {
      if (isAxiosError(error) && error.response) {
        switch (error.response.status) {
          case 404:
            notify('custom.notification.error.not_found', {
              messageArgs: { item: translate(errorMessage) },
              type: 'error',
            });
            break;
          case 200:
            notify('custom.notification.success', {
              messageArgs: { action: translate(successMessage) },
              type: 'success',
            });
            break;
          default:
            notify('ra.page.error', { type: 'error' });
        }
      } else {
        console.debug(error);
      }
    } finally {
      setLoading(false);
      setAction('');
    }
  };

  const refreshConfig = async () => {
    await handleAction('refresh-config', 'resources.units.show.links.config', 'ra.action.refresh');
  };

  const fixMissingSimCard = async () => {
    await handleAction('fix-missing-sim', 'resources.units.show.links.sim', 'ra.action.refresh');
  };

  const refreshByIfPanel = async () => {
    await handleAction('refresh-by-ifpanel', 'resources.units.show.links.config', 'ra.action.refresh');
  };

  if (!record) return null;

  const links = [
    {
      label: 'resources.units.show.links.terminal',
      icon: <Terminal />,
      linkName: 'terminalLink',
    },
  ];

  return (
    <>
      <TopToolbar>
        {hardware_id >= 3 && (
          <Button onClick={() => setOpen(true)} size="small" startIcon={<Terminal />}>
            {translate('resources.units.show.links.rsh')}
          </Button>
        )}
        <RSHDialog open={open} handleClose={handleClose} record={record} />
        {record &&
          links.map((link, index) => (
            <Button
              key={`link-${index}`}
              size="small"
              startIcon={link.icon}
              href={record[link.linkName]}
              disabled={!record[link.linkName] || record[link.linkName] === 'about:blank'}
              target="_blank"
            >
              {translate(link.label)}
            </Button>
          ))}
        <LoadingButton
          loading={loading && action === 'refresh-config'}
          loadingPosition="start"
          size="small"
          startIcon={<Refresh />}
          onClick={refreshConfig}
          disabled={(loading && action === 'refresh-config') || !hasPermission('dev_options')}
          sx={{
            'backgroundColor': '#0176d3',
            'color': '#fff',
            '&:hover': { backgroundColor: '#0f94fe', color: '#fff' },
            '&.Mui-disabled': { color: '#fff' },
          }}
        >
          {translate('resources.units.show.links.refresh_config')}
        </LoadingButton>
        {!record?.mobile_network?.sim_id && record.hardware_id === 3 && (
          <LoadingButton
            loading={loading && action === 'fix-missing-sim'}
            loadingPosition="start"
            size="small"
            startIcon={<Refresh />}
            onClick={fixMissingSimCard}
            disabled={loading && action === 'fix-missing-sim'}
          >
            {translate('resources.units.show.links.fix_missing_sim')}
          </LoadingButton>
        )}
        {record.hardware_id === 2 && (
          <LoadingButton
            sx={{
              'backgroundColor': '#292e5c',
              'color': '#fff',
              '&:hover': { backgroundColor: '#3f4d9e', color: '#fff' },
              '&.Mui-disabled': { color: '#fff' },
            }}
            loading={loading && action === 'refresh-by-ifpanel'}
            loadingPosition="start"
            size="small"
            startIcon={<Refresh />}
            onClick={refreshByIfPanel}
            disabled={loading && action === 'refresh-by-ifpanel'}
          >
            {translate('resources.units.show.links.refresh_from_ifpanel')}
          </LoadingButton>
        )}
        <EditTabButton />
      </TopToolbar>
      {record?.developer_options?.staging_api && (
        <Alert variant="filled" severity="warning" sx={{ mb: 2 }}>
          {translate('resources.units.uses_staging_api')}
        </Alert>
      )}
    </>
  );
};

const UnitShow = () => {
  const { hasPermission } = usePermission();
  const navigate = useNavigate();
  const currentPath = useLocation();

  useEffect(() => {
    if (!/units\/\d+\/show\/[a-z]+/.test(currentPath.pathname)) {
      navigate(currentPath.pathname + '/info');
    }
  }, [currentPath.pathname, navigate]);

  return (
    <Show actions={<UnitActions />}>
      <TabbedShowLayout>
        <InfoTab />
        <ConfigTab />
        <AppsTab />
        {hasPermission('dev_options') && <DevTab />}
        {hasPermission('dev_options') && <DiagnosticsTab />}
        {hasPermission('dev_options') && <MobileNetwork />}
        {hasPermission('dev_options') && <ActivityLogTab />}
      </TabbedShowLayout>
    </Show>
  );
};

export default UnitShow;
